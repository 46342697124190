import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    useRefresh,
    Datagrid, ArrayField, SingleFieldList, useRecordContext,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {DateFieldComp, TextFieldBold, TextFieldButton} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";
import {FloatNumber, formatSpacerNumber, GetIssuedAirline} from "../booking/BookingColumnsForList";
import {Box, Button, Grid} from "@material-ui/core";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {saveInvoiceToExcel} from "../_common/utils";
import MyEditableDatagridForReference from "../_common/MyEditableDatagridForReference";
import PostPagination from "../_common/PostPagination";
import moment from "moment";
import get from "lodash/get";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {invoice_id, customer_id} = props;

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{invoice_id: invoice_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <ReferenceInput
                    source="booking_id"
                    reference="booking"
                    validate={validateReq}
                    filter={{customer_id: customer_id, is_invoiced: 'false', is_paid: 'false'}}
                >
                    <AutocompleteInput
                        resettable
                        filterToQuery={filterToQuery}
                        //filterToQuery={(filterValue) => ({ tttt: filterValue })}
                        optionText="repr"
                        //optionText={record => (record && record.id ? `${record.route} #${record.id} ($ ${record.sale_total_dollar})` : '-')}
                        fullWidth
                    />
                </ReferenceInput>

            </RowForm>
        </>
    )
};

const GetPnr = (props) => {
    //const {classes} = props;
    const record = useRecordContext(props);
    let transaction_type = get(record, 'booking_details.transaction_type', null);
    let passengers = get(record, 'booking_details.passengers', []);
    let references = get(record, 'booking_details.references', []);

    if (transaction_type === 'non_flight') {
        return (
            <Box style={{display: 'grid'}}>
                {passengers.map((it, ind) => {
                    return (
                        <Box style={{width: '100%'}} key={ind}>{it.ticket_number}</Box>
                    )
                })}

            </Box>
        )
    } else {//airline.code
        return (
            <Box style={{display: 'grid'}}>
                {references.map((it, ind) => {
                    return (
                        <Box style={{display: 'flex'}} key={ind}>
                            <Box style={{width: '100%'}}>{it.pnr}</Box>
                            <Box>({it.airline.code})</Box>
                        </Box>
                    )
                })}

            </Box>
        )
    }
}
const GetRoute = (props) => {
    //const {classes} = props;
    const record = useRecordContext(props);
    let transaction_type = get(record, 'transaction_type', null);
    let ticket_type = get(record, 'ticket_type', null);
    let legs = get(record, 'legs', []);

    if (transaction_type === 'non_flight') {
        let non_flight_transactions = get(record, 'non_flight_transactions', []);
        //let res = []
        let res = non_flight_transactions.map((x)  => x.route)
        return (
            <Box display={'grid'}>
                <span>{'non flight'}</span>
                <span><b>{res.join(', ')}</b></span>
            </Box>
        )
    }
    switch (ticket_type) {
        case 'single':
            return (
                <TextFieldBold source={`route`}/>
            )
        case 'rtn':
            return (
                <Box display={'grid'}>
                    <div><TextField source={`from_place`}/> - <TextField source={`to_place`}/></div>
                    <div><TextField source={`to_place`}/> - <TextField source={`from_place`}/></div>
                </Box>
            )
        case 'multi_city':
            return (
                <Box style={{display: "grid"}}>
                    {legs.map((it, ind) => {
                        return (
                            <b key={ind}>{it.route.toUpperCase()}</b>
                        )
                    })}
                </Box>
            )
        default:
            return null
    }
}
const GetTravelDate = (props) => {
    const {classes} = props;
    const record = useRecordContext(props);
    //let ticket_type = get(record, 'ticket_type', null);
    let transaction_type = get(record, 'transaction_type', null);
    let non_flight_transactions = get(record, 'non_flight_transactions', null);
    switch (transaction_type) {
        case 'non_flight':
            return (
                <>
                    {
                        non_flight_transactions && non_flight_transactions.map((it, ind) => {
                            return (
                                <span key={ind} style={{fontSize: 11}}>
                                    {moment(it.date).format("D-MMM-YY")}
                                    {it.date_to ? ` - ${moment(it.date_to).format("D-MMM-YY")}` : ' - ...'}
                                </span>
                            )
                        })}
                </>
            )
        default:
            return (
                <>
                    <DateFieldComp source="travel_date"
                                   headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={false}
                                   locale={'us'}/>
                    <DateFieldComp source="travel_date_return"
                                   headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={false}
                                   locale={'us'}/>
                </>
            )
    }
}
const ColumnsForList = (resource, classes, customerFormId) => {
    let columns = {
        id: <ReferenceField source="booking_id" reference="booking" label={'Id'} link={false}>
            <TextField source={'id'}/>
        </ReferenceField>,
        /*customer: <ReferenceField
            source="booking_id"
            reference="booking"
            label={'Customer'}
            link={false}
            //cellClassName={customer_id !== customerFormId && classes.alarmField}
        >
            <ReferenceField source="customer_id" reference="customer" link={false}>
                <TextFieldButton
                    bold={false}
                    source="name"
                    if_field={'id'}
                    if_value={customerFormId}
                />
            </ReferenceField>
        </ReferenceField>,*/
        travel_date:
            <ReferenceField
                source="booking_id"
                reference="booking"
                label={'Travel Date'}
                link={false}
                //sortable={false}
                sortBy={'booking:travel_date'}
                cellClassName={classes.date_non_fl}
                //textAlign={"right"}
            >
                <Box display={"grid"} label={'Travel date'}>
                    <GetTravelDate classes={classes}/>
                </Box>
            </ReferenceField>,
        booking_date:
            <ReferenceField
                source="booking_id"
                reference="booking"
                label={'Booking Date'}
                //link={(row) => `/booking/${row.booking_details.id}`}
                link={false}
                //sortable={false}
                sortBy={'booking:booking_date'}
                cellClassName={classes.date_st}
                //textAlign={"right"}
            >
                <DateFieldComp source="booking_date" label={'Booking Date'}
                               sortBy={'booking:booking_date'}
                               headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={false}
                               locale={'us'}/>
            </ReferenceField>,
        issued_airline_id:
            <Box link={false} label={'Issued airline'} sortable={false}
                 cellClassName={classes.airline_st}>
                <ReferenceField source="booking_id" reference="booking" link={false}>
                    {/*<ReferenceField source="issued_airline_id" reference="airline" label={'Issued airline'}
                                    link={false}>
                        <GetString source="title" title={'code'}/>
                    </ReferenceField>*/}
                    <GetIssuedAirline
                        label={'Issued airline'}
                        headerClassName={classes.airline_st}
                        cellClassName={classes.airline_st}
                    />
                </ReferenceField>
            </Box>,
        route:
            <ReferenceField source="booking_id" reference="booking" label={'Travel Route'} sortable={false}>
                {/*<TextFieldBold source={'route'}/>*/}
                <GetRoute/>
            </ReferenceField>,

        f_name:
            <Box link={false} label={'Passenger'} sortable={false} textAlign={"left"}>
                <ReferenceField source="booking_id" reference="booking" link={false}>
                    <ArrayField source="passengers" label={'Passenger First Name Initial'}>
                        <SingleFieldList style={{display: "grid", lineHeight: 0.3}} linkType={false}>
                            {/*<TextField source="f_name"/>*/}
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                // copyClipBoard={true}
                                source="title"
                                source2="f_name"
                                source3="l_name"
                                bold={false}
                                //if_field={'customer_id'}
                                //if_value={customer_id}
                            />
                        </SingleFieldList>
                    </ArrayField>
                </ReferenceField>
            </Box>,
        pnr:
            <Box link={false} label={'PNR / Booking Ref'} sortable={false}>
                <GetPnr/>
                {/*<ReferenceField source="booking_id" reference="booking" link={false}>
                    <ArrayField
                        source="references" label={'PNR / Booking Ref'}
                        cellClassName={classes.pnr_st}
                        style={{display: 'block'}}
                    >
                        <SingleFieldList linkType={false}>
                            <Box style={{display: 'flex'}}>
                                <Box style={{width: '100%'}}><TextField source="pnr"/></Box>
                                <Box>(<TextField source="airline.code"/>)</Box>
                            </Box>

                        </SingleFieldList>
                    </ArrayField>
                </ReferenceField>*/}
            </Box>,

        total: <ReferenceField
            source="booking_id"
            reference="booking"
            label={'Total'}
            link={false}
            //sortable={false}
            sortBy={'booking:sale_total_dollar'}
            headerClassName={classes.price_st}
            cellClassName={classes.price_st}
            textAlign={"right"}
        >
            <FloatNumber fix={2} source={`sale_total_dollar`} label={'Total'}/>
        </ReferenceField>,

    }
    return columns;
};

const InvoiceBooking = props => {
    const {invoice_id, customer_id, customerFormId, total_amount, record, isSave = true} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    //console.log('record=======>', record);

    const columns = useSelectedColumns({
        preferences: `${'invoice_booking_'}list.columns`,
        columns: ColumnsForList('invoice_booking', classes, customerFormId),
        omit: ["nb_views"],
    });

    const getInvoiceBooking = async (invoice_id, record) => {
        const url = apiPathConfig.getApiUrl()
        let report_url = `booking/admin/invoice_booking?invoice_id=${invoice_id}&limit=500&ordering=-id&skip=0`;
        try {
            const response = await httpClient(`${url}${report_url}`);
            if (response.status >= 200 && response.status <= 300) {
                // console.log('response=======>', response.json.results);
                saveInvoiceToExcel(response.json.results, record);
            } else {

            }
        } catch (err) {
            //console.log('err=======>', err);
        } finally {
            //console.log('finally=======>', 'finally');
            //refresh();
        }
    }
    const getData = async (invoice_id) => {
        const url = apiPathConfig.getApiUrl()
        let report_url = `api/common/invoice/${invoice_id}/auto-fill?`;
        try {
            const response = await httpClient(`${url}${report_url}`);
            if (response.status >= 200 && response.status <= 300) {

            } else {

            }
        } catch (err) {
            console.log('err=======>', err);
        } finally {
            //console.log('finally=======>', 'finally');
            refresh();
        }
    }

    const handleAllBooking = (event, invoice_id) => {
        event.preventDefault();
        event.stopPropagation();
        getData(invoice_id);
        //redirect(`/booking/91/`)
    };
    const ExportExcel = (event, invoice_id, record) => {
        event.preventDefault();
        event.stopPropagation();
        getInvoiceBooking(invoice_id, record);
        //getData(invoice_id);
        //redirect(`/booking/91/`)
    };

    return (
        <div style={{width: "max-content", minWidth: 500, marginBottom: 16}}>
            {/*<img width={'100'} src={'../img/pay6152.jpg'} alt={'jfsdlj'}/>*/}
            {total_amount
                ?
                <Button
                    disabled={customer_id !== customerFormId}
                    variant={"outlined"}
                    size="small"
                    color="primary"
                    onClick={(event) => ExportExcel(event, invoice_id, record)}
                    style={{float: "right"}}
                >
                    Export to Excel
                </Button>
                : null
            }
            {isSave
                ?
                <Button
                    disabled={customer_id !== customerFormId}
                    size="small"
                    color="primary"
                    onClick={(event) => handleAllBooking(event, invoice_id)}
                    style={{marginRight: 24}}
                >
                    Fill all bookings
                </Button>
                : null
            }
            {total_amount ? <div style={{marginTop: 16}}/> : null}
            {customer_id !== customerFormId
                ? record.qty_of_bookings
                    ? <Alert severity='warning'>The current bookings do not belong to this customer</Alert>
                    : <Alert severity='warning'>For further work save the CUSTOMER field</Alert>
                : null
            }
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={50}
                fullWidth
                //label="qwqwqwq "
                reference="invoice_booking"
                target="invoice_id"
            >
                {isSave && customer_id === customerFormId
                    ?
                    /*<EditableDatagrid*/
                    <MyEditableDatagridForReference
                        classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                        noDelete={customer_id !== customerFormId}
                        mutationMode="pessimistic" //Подтвердить удаление
                        undoable
                        createForm={<ListEditForm submitOnEnter={false} invoice_id={invoice_id}
                                                  customer_id={customer_id} customerFormId={customerFormId}/>}
                        editForm={<ListEditForm submitOnEnter={false} invoice_id={invoice_id}
                                                customer_id={customer_id} customerFormId={customerFormId}/>}
                        //rowStyle={postRowStyle}
                    >
                        {columns}
                    </MyEditableDatagridForReference>
                    :
                    <Datagrid
                        classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    >
                        {columns}
                    </Datagrid>
                }
            </ReferenceManyField>

            {/*<Typography variant="body1" style={{textAlign: "end", marginRight: 90}}>
                Total amount: {formatSpacerNumber(total_amount)}
            </Typography>*/}

            {total_amount >= 0
                ?
                <Grid container style={{marginTop: 24, textAlign: "end"}}>
                    <Grid item xs={12}>
                        <div className={classes.cost_show_field_calc}
                             style={{marginRight: isSave && customer_id === customerFormId && 84}}>
                            <div>
                                <span style={{fontSize: 12, fontWeight: "bold"}}>{'Total Amount ($):'}</span>
                                <div style={{width: 120, display: "inline-block", fontWeight: "bold"}}>
                                    {total_amount ? formatSpacerNumber(total_amount) : 0}
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                : null
            }
            {record?.date_xero_export
                ?
                <>
                    <Grid container style={{marginTop: 24, textAlign: "end"}}>
                        <Grid item xs={12}>
                            <div className={classes.cost_show_field_calc}
                                 style={{marginRight: isSave && customer_id === customerFormId && 84}}>
                                <div>
                                    <span style={{
                                        fontSize: 12,
                                        fontWeight: "bold"
                                    }}>{'Exported to Xero Amount ($):'}</span>
                                    <div style={{width: 120, display: "inline-block"}}>
                                        {record?.total_amount_xero_export ? formatSpacerNumber(record?.total_amount_xero_export) : 0}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop: 24, textAlign: "end"}}>
                        <Grid item xs={12}>
                            <div className={classes.cost_show_field_calc}
                                 style={{marginRight: isSave && customer_id === customerFormId && 84}}>
                                <div>
                                    <span style={{fontSize: 12, fontWeight: "bold"}}>{'Exported to Xero Date:'}</span>
                                    <div style={{width: 120, display: "inline-block"}}>
                                        {moment(record.date_xero_export).format("D-MMM-YY, H:mm")}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </>
                : null

            }

        </div>
    )
}
export default InvoiceBooking;