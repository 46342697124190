//import * as React from 'react';
//import {TextInput} from 'react-admin';
import {
    ReferenceInput,
    AutocompleteInput, FilterLiveSearch, TextInput, SelectInput
} from 'react-admin';
import {DateInputComp} from "../_common/CompReact";
import * as React from "react";
import {commonTrueFalse, commonYesNo, paymentType, ticketType, transactionType} from "../utils/choices";

export const BookingTopFilters = (resource, locale) => {

    let dt = [];
    dt.push(<SelectInput source="ticket_type" label={'Ticket Type'} choices={ticketType}
                         emptyText={'All Types'} resettable key={'Ticket Type'}/>);

    dt.push(<TextInput label="Search" source="q" key={'Search'}/>);
    dt.push(<TextInput label="PNR" source="bookings_reference_pnr" key={'PNR'}/>);
    dt.push(<TextInput label="Sup Booking Ref" source="booking_code" key={'Sup Booking Ref'}/>);

    dt.push(<TextInput label="Ticket Number" source="ticket_number" key={'Ticket Number'}/>);

    dt.push(<TextInput label="Passenger (FN)" source="passenger_f_name" key={'Passenger_f_name'}/>);
    dt.push(<TextInput label="Passenger (LN)" source="passenger_l_name" key={'Passenger_l_name'}/>);

    /*dt.push(<BooleanInput source="is_paid" label={'Is paid'}/>);*/
    /*dt.push(<SelectInput source="is_load"
                         label={'Exported to Xero'}
                         choices={commonYesNo}
                         emptyText={'All'}
                         //defaultValue={'Y'}
                         resettable
    />);*/
    dt.push(<SelectInput source="is_paid" label={'Is paid'} choices={commonYesNo}
                         emptyText={'All Paid'} resettable key={'Is Paid'}/>);
    dt.push(<SelectInput source="is_invoiced" label={'Is Invoiced'} choices={commonTrueFalse}
                         emptyText={'All Invoice'} resettable key={'Is Invoiced'}/>);

    dt.push(<SelectInput source="draft" label={'Is Draft'} choices={commonTrueFalse}
                         emptyText={'All'} resettable key={'Is Draft'}/>);
    dt.push(<SelectInput source="canceled" label={'Canceled'} choices={commonTrueFalse}
                         emptyText={'All'} resettable key={'Canceled'}/>);

    dt.push(<SelectInput source="transaction_type" label={'Transaction type'}
                         choices={transactionType} emptyText={'All Transactions'} resettable
                         key={'Transaction type'}/>);
    dt.push(<SelectInput source="payment_type" label={'Payment type'} key={'Payment type'}
                         choices={paymentType} emptyText={'All Payment'} resettable/>);
    dt.push(<ReferenceInput source="customer_id" reference="customer" key={'Customer'}>
        <AutocompleteInput optionText="name" resettable emptyText={'All customers'}/>
    </ReferenceInput>);
    dt.push(<FilterLiveSearch source={'route'} label={'Travel Route'} key={'Travel Route'}/>);
    dt.push(<ReferenceInput source="program_id" reference="programs" label={'Programs'} key={'Programs'}>
        {/*<AutocompleteInput optionText={`attributes.name_${locale}`} resettable emptyText={'All locations'}/>*/}
        <AutocompleteInput optionText="title" resettable emptyText={'All Programs'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="issued_airline_id" reference="airline" label={'Issued airline'} sort={{field: 'title', order: 'ASC'}}
                            key={'Issued airline'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.code ? `${record.title} (${record.code})` : 'All airlines')}
            resettable
            emptyText={'All airlines'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="passenger_id" reference="passenger" label={'Passenger'} key={'Passenger'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.title ? `${record.title}. ${record.f_name} ${record.l_name}` : 'All Passengers')}
            resettable
            emptyText={'All Passengers'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="dealer_id" reference="supplierData" label={'Dealer'} filter={{is_miles: true}}
                            key={'Dealer'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.title ? `${record.title}` : 'All Dealers')}
            resettable
            emptyText={'All Dealers'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="supplier_id" reference="supplierData" label={'Supplier'} filter={{is_miles: false}}
                            key={'Supplier'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.title ? `${record.title}` : 'All Suppliers')}
            resettable
            emptyText={'All Suppliers'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="program_account_id" reference="supplier" label={'Account number'}
                            key={'Account number'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.account_number ? `${record.account_number}` : 'All Account number')}
            resettable
            emptyText={'All Account number'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="card_id" reference="creditCard" label={'Credit Card'} key={'Credit Card'}>
        <AutocompleteInput
            optionText={record => (record &&
            record.last_cc ? `${record.description} *${record.last_cc}` : 'All Credit Card')}
            resettable
            emptyText={'All Credit Card'}
        />
    </ReferenceInput>);
    /*dt.push(<ReferenceInput source="program_account_id" reference="supplier" label={'Supplier (mi)'} alwaysOn>
        <AutocompleteInput
            optionText={record => (record &&
            record.supplier_title ? `${record.supplier_title}` : 'All Supplier')}
            resettable
            emptyText={'All Supplier'}
        />
    </ReferenceInput>);*/

    /*dt.push(<ReferenceInput source="operated_airline_id" reference="airline" label={'Operated airline'} alwaysOn>
            <AutocompleteInput
                optionText={record => (record &&
                    record.code ? `(${record.code}) ${record.title}` : 'All airlines')}
                resettable
                emptyText={'All airlines'}
            />
        </ReferenceInput>);*/
    dt.push(<DateInputComp source={"travel_date_gte"} label={"Travel date from"} fullWidth key={'Travel date from'}/>);
    dt.push(<DateInputComp source={"travel_date_lte"} label={"Travel date to"} fullWidth key={'Travel date to'}/>);
    dt.push(<DateInputComp source={"booking_date_gte"} label={"Booking date from"} fullWidth
                           key={'Booking date from'}/>);
    dt.push(<DateInputComp source={"booking_date_lte"} label={"Booking date to"} fullWidth key={'Booking date to'}/>);

    // dt.push(<TextInput label="Search" source="q" alwaysOn />)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!" />);
    // dt.push(<TextInput label="Customer" source="customer"/>)
    // dt.push(<TextInput label="Passenger name" source="passanger_name"/>)
    // dt.push(<TextInput label="Airline" source="airline"/>)
    // dt.push(<TextInput label="Program Name" source="programName"/>)

    dt.sort((a, b) => (a.key > b.key) ? 1 : -1)
    return dt;
}
