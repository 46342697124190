import {useRecordContext, useUpdate} from "react-admin";
import * as React from "react";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {getStatusById} from "../utils/choices";
import get from 'lodash/get';

const SelectAndSaveChoices = (props) => {
    const {
        source, statuses, resource,
        showTextValue, disableCondition,
        width, edit = false, color = false,
        is_field_no_edit = false,
        backgroundColor = '#eee',
        icon = null
    } = props;

    let editEl = edit;
    // const refresh = useRefresh();
    const [update, {loading, error}] = useUpdate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (editEl) setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        event.stopPropagation();
    };
    const onClick = (event, v) => {
        handleClose(event);
        const data = {id,}
        data[source] = v
        update(resource, id, data, record);
    }

    const record = useRecordContext(props);
    if(!record) return null;
    const {id} = record || {};
    const status = get(record, source, null)
    if(is_field_no_edit) { editEl = get(record, is_field_no_edit, null); editEl = editEl ? false : true }

    //if (editEl === false && status === null) return null;

    if (error) {
        return <p>ERROR</p>;
    }
    const result = getStatusById(status, statuses);
    const {id: idRes, name, img} = result || {};
    // const {id: idRes, name, img, color} = result || {};
    return <div>
        <Button
            style={{
                backgroundColor: showTextValue && backgroundColor,
                cursor: "pointer",
                color: color
                    ? idRes ? color : 'red'
                    : 'inherit',
                width: width && width,
                padding: '4px 4px 2px 4px',
                fontSize: 12,
                opacity: (edit && !editEl) ? 0.3 : 1,
            }}
            title={`${'id: '}${status} / ${'name: '}${name}`}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            // disabled={loading || (disableCondition && !disableCondition(record))}
            disabled={loading || disableCondition}
            startIcon={icon ? icon : false}
        >
            {showTextValue ? name : img}
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {statuses.map(v => (
                <MenuItem style={{color: v.color && v.color}}
                          key={v.id}
                          onClick={(event) => onClick(event, v.id)}
                >
                    {v.image} {v.name}
                </MenuItem>))}
        </Menu>
    </div>;
}

export default SelectAndSaveChoices
