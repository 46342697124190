/*export const accountTypes = {
    man: 'MAN',
    woman: 'WOMAN',
    couple: 'COUPLE',

    isCouple: (type) => (type === accountTypes.couple),
    showMan: (type) => ([accountTypes.man, accountTypes.couple].includes(type)),
    showWoman: (type) => ([accountTypes.woman, accountTypes.couple].includes(type)),

    showManFields: (types) => (types && (types.includes(accountTypes.man) || types.includes(accountTypes.couple))),
    showWomanFields: (types) => (types && (types.includes(accountTypes.woman) || types.includes(accountTypes.couple))),
}*/


export const getStatusById = (id, statuses) => {
    let res = statuses.find(x => x.id === id);
    //console.log('id, statuses===>', id, statuses, res);
    if (res)
        return {
            id: res.id,
            name: res.name,
            short_name: res.short_name ? res.short_name : res.id,
            img: res.image ? res.image : '',
            color: res.color ? res.color : '',
        };
    else return {id: false, name: '', short_name: '', img: '', color: ''};
    // for (const x of statuses) { if (x.id===id) return `${x.image}`}
}
//====================== Common ====================================
/*
export const statusArticleSection = [
    {id: 0, name: 'INACTIVE', short_name: 'INACTIVE', image: '👎'},
    {id: 1, name: 'ACTIVE', short_name: 'ACTIVE', image: '👍'},
]
*/
export const ticketClass = [
    {id: 'economy', name: 'Economy'},
    {id: 'first', name: 'First'},
    {id: 'business', name: 'Business'},
    {id: 'premium', name: 'Premium'},
]
export const ticketType = [
    {id: 'single', name: 'Single'},
    {id: 'rtn', name: 'Return'},
    {id: 'multi_city', name: 'Multi city'},
]
export const paymentType = [
    {id: 'cash', name: 'Cash'},
    {id: 'miles', name: 'Miles'},
    {id: 'miles_only', name: 'Miles only'},
]
export const paymentTypeOnlyCash = [
    {id: 'cash', name: 'Cash'},
]
export const titleMrMs = [
    {id: 'mr', name: 'Mr'},
    {id: 'ms', name: 'Ms'},
]
export const isCompany = [
    {id: true, name: 'Company'},
    {id: false, name: 'Private Person'},
]
export const allCustomers = [
    {id: true, name: 'All Customers'},
    {id: false, name: 'Current Customer'},
]


export const commonYesNo = [
    {id: 'Y', name: 'YES', image: '👍'},
    {id: 'N', name: 'NO', image: '👎'},
]
export const commonTrueFalse = [
    {id: true, name: 'YES', image: '👍'},
    {id: false, name: 'NO', image: '👎'},
]
export const currencyRounding = [
    {id: 'no_rounding', name: 'no_rounding'},
    {id: 'rounded_up', name: 'rounded_up'},
    {id: 'arithmetical_rules', name: 'arithmetical_rules'},
]
export const hidden_city_flight_status = [
    {id: 'refund_to_cash', name: 'Refund to cash'},
    {id: 'refund_to_airlines_credits', name: 'Refund to Airlines credits'},
    {id: 'non_refundable', name: 'Non-Refundable'},
    {id: 'still_in_process', name: 'Still In Process'},
]
export const transactionType = [
    {id: 'booking', name: 'Booking'},
    {id: 'dead_end_refund', name: 'Dead-end refund'},
    {id: 'refund', name: 'Refund'},
    {id: 'changing', name: 'Change'},
    {id: 'non_flight', name: 'Non Flight'},
]
export const historyAction = [
    {id: 'create', name: 'Create'},
    {id: 'update', name: 'Update'},
    {id: 'delete', name: 'Delete'},
]
export const resourceTables = [
    {id: 'customer', name: 'customer'},
    {id: 'currency', name: 'currency'},
    {id: 'airline', name: 'airline'},
    {id: 'bank', name: 'bank'},
    {id: 'bookings_passenger', name: 'Bookings passenger'},
    {id: 'passenger', name: 'Passenger'},
    {id: 'booking', name: 'General Sales'},
    {id: 'program_account', name: 'Program Account'},
    {id: 'bookings_reference', name: 'Bookings Reference'},
    {id: 'program', name: 'Programs'},
    {id: 'supplier', name: 'Suppliers/Dealers'},
    {id: 'hidden_city', name: 'Hidden City'},
    {id: 'card', name: 'Credit Card'},
    {id: 'refund', name: 'Refund'},
    {id: 'travel_credit', name: 'Travel Credit'},
]
//====================== RichTextInput =========================

export const toolbarRichTextInput = [
    // [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'font': []}],

    ['video'],
    // ['insert-code', 'code-insert'],
    ['image', 'link'],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'align': []}],

    ['clean']                                         // remove formatting button
    /*
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']
    */
]